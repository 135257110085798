<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="operationKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Corporate Kits
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const formTitle = 'Corporate Kits'

export default {
  components: {
    KitsPanel: () => import('@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Operation Kits', disabled: false, to: '/operation/operation_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      operationKits: [
        /*
        {
          icon: 'mdi-account-tie',
          path: '/operation/corporate_dealer',
          title: 'Dealers',
          desc: 'Corporate dealer management.'
        },
        {
          icon: 'mdi-account-group',
          path: '/operation/corporate_activation',
          title: 'Activations',
          desc: 'Corporate bulk activations.'
        },
        */
        {
          icon: 'mdi-cash-register',
          path: '/operation/corporate_payment',
          title: 'Payments',
          desc: 'Corporate bulk payments.',
        },
        {
          icon: 'mdi-history',
          path: '/operation/reserved_history',
          title: 'Reserved Number',
          desc: 'Selected from Pool',
        },
      ],
    }
  },
}
</script>
